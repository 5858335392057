<template>
  <div>
    <div>
      <CModal :title="submitType === 'Update'? $lang.post.crud.edit: $lang.common.general.reason" size="lg" :show.sync="largeModal" :no-close-on-backdrop="true" color="primary">
        <CCardBody>
          <CRow>
            <CCol md="12">
              <c-textarea
                  v-model="post.reason"
                  :rows="5"
                  :cols="10"
                  placeholder="Add Reason"
              ></c-textarea>
            </CCol>
            <CCol md="6">
              <div class="form-group">
                <CButton
                    color="primary"
                    v-c-tooltip="$lang.post.crud.post_status"
                    :disabled="submitted"
                    v-on:click="blockPost(2)"
                >
                  Submit
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
        <template #footer style="display: none">
          <CButton @click="darkModal = false" color="danger" style="display: none">Discard</CButton>
          <CButton @click="darkModal = false" color="success" style="display: none">Accept</CButton>
        </template>
      </CModal>


      <CModal
          size="lg"
          :show.sync="imageLargeModal"
          :no-close-on-backdrop="true"
          color="primary"
          :hide-footer="true"
      >

        <img alt="Registration Document" class="imageFormat" :src="viewImage"/>


        <template #footer style="display: none">
          <CButton @click="darkModal = false" color="danger" style="display: none"
          >Discard
          </CButton
          >
          <CButton
              @click="darkModal = false"
              color="success"
              style="display: none"
          >Accept
          </CButton
          >
        </template>
      </CModal>


    </div>
    <CCard>
      <CCardHeader>
        <div class="row">
          <CCol sm="6" class="align-self-center">
            <strong>{{ $lang.group_change.crud.detail }}</strong>
          </CCol>
        </div>
      </CCardHeader>
      <CCardBody>
        <CCol md="12" class="align-self-center">
          <CRow>
          <make-col :sm="6" :md="4" :lg="4" :title="$lang.group_change.form.name" :value="detail.name" ></make-col>
          <make-col v-show="requestedName" :sm="6" :md="4" :lg="4" :title="$lang.group_change.form.requestedName" :value="requestedName" ></make-col>
          <make-col :sm="6" :md="4" :lg="4" :title="$lang.group_change.form.title" :value="detail.title" ></make-col>
          <make-col :sm="6" :md="4" :lg="4" :title="$lang.group_change.form.link" :link="detail.link" :value="detail.link" ></make-col>
          <make-col :sm="6" :md="4" :lg="4" :title="$lang.group_change.form.status" :badge="detail.status" :value="detail.status"  ></make-col>
          <make-col :sm="6" :md="4" :lg="4" :title="$lang.group_change.form.uploadDocByAdmin" :value="detail.uploadDocByAdmin"  ></make-col>
          </CRow>
        </CCol>
        <CCol md="12" class="align-self-center">
          <entity-time-stamp :createdAt="detail.createdAt" :updatedAt="detail.updatedAt"></entity-time-stamp>
        </CCol>
        <CCol col="12">
          <p class="custome-labal"><b>{{ $lang.common.label.attachments }}</b> : </p>
          <div id="people">
            <div class="row row-sm">
              <div
                  v-for="(media) in detail.media"
                  :key="media.id"
                  class="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3"
              >
                <div class="card card-file media">
                  <div class="card-file-thumb">
                    <i class="fa fa-file-word-o"></i>
                  </div>
                  <div class="card-body text-center">
                    <div v-if="media.mediaName === 'image'">
                      <img
                          :src="media.url"
                          alt="Media"
                          @click="open(media.url, media.type)"
                          style="cursor: pointer"
                          height="98"
                          class="mx-auto d-block"
                      />
                      <template>
                        <CDropdown toggler-text="Actions">
                          <CDropdownItem :href="media.url" target="_blank">Download</CDropdownItem>
                        </CDropdown>
                      </template>
                    </div>
                    <div v-else-if="media.mediaName !== 'image'">
                      <img
                          :src="media.defaultImage"
                          alt="Default Image"
                          height="98"
                          class="mx-auto d-block"
                      />
                      <a  :href="viewDocument(media.type,media.url)" target="_blank">View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CCol>
        <CCol md="12" class="align-self-center">
          <CRow v-show="post.blockedReason">
            <CCol sm="12" align="left">
              <strong>Blocked Reason: </strong> {{ post.blockedReason }}
            </CCol>
          </CRow>
          <CRow v-if="!post.blockedReason">
            <CCol sm="12" align="left">
              <CButton color="primary" v-c-tooltip="statusLabel" v-on:click="beforeBlocked()"
                       v-if="detail.status === 'Pending'">
                Reject
              </CButton> &nbsp;
              <CButton color="success" v-c-tooltip="statusLabel" v-on:click="beforeApprved()"
                       v-if="detail.status === 'Pending'">
                Approve
              </CButton>
            </CCol>
          </CRow>
        </CCol>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {posts} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import VueNotifications from "vue-notifications";
import EntityTimeStamp from "/src/components/EntityTimeStamp";
import MakeCol from "../../../components/MakeCol";

Vue.use(VueNotifications);
Vue.use(VueForm);

export default {
  name: "DetailProfileTab",
  mixins: [Mixin],
  components: {
    MakeCol,
    EntityTimeStamp
  },
  data() {
    return {
      statusId: "",
      requestedName:"",
      statusLabel: "Block",
      statusColor: "danger",
      submitted: false,
      largeModal: false,
      imageLargeModal: false,
      submitType: "",
      statusOption: [],
      showTax: true,
      err_msg: "",
      dismissCountDown: 0,
      self: this,
      module: posts,
      detail: {},
      viewImage:"",
      post: {
        reason: "",
        isActive: 1,
        blockedReason: '',
      },
    };
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;
    axios
        .post('users/group/request/detail/' + id)
        .then((response) => {
          // eslint-disable-next-line no-empty
          if (response.data) {
            self.detail = response.data.data;
            self.requestedName = self.detail.requestedName ?? null;
            store.commit("showLoader", false); // Loader Off
          }
        })
        .catch(function () {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {
    viewDocument(type,url) {
      // `this` points to the component instance
      if(type=='doc') {
        return "https://docs.google.com/gview?embedded=true&url=" + encodeURIComponent(url);
      }else if (type=='pdf'){
        // return  url + "?embedded=true#toolbar=0";
        return "https://docs.google.com/gview?embedded=true#toolbar=0&url=" + encodeURIComponent(url);

      }
    },

    open(url) {
      let self = this;
      self.viewImage = url;
      //  fancyBox(e.target,this.images);
      self.imageLargeModal = true;
    },
    beforeBlocked() {
      let self = this;
      self.post.reason = "";
      if (self.post.isActive === 1) {
        this.largeModal = true;
      } else {
        this.blockPost(2);
      }
    },
    beforeApprved() {
      let self = this;
      self.post.reason = "";
      this.blockPost(3);
    },

    blockPost(statusId) {
      let self = this;
      self.submitted = true; //Enable Button
      if (statusId === 2) {
        if (self.post.reason === "") {
          self.submitted = false; //Enable Button
          return false;
        }
        const id = this.$route.params.id;
        setTimeout(() => {
          let self = this;
          const postData = {
            status: statusId,
            reason: self.post.reason,
          };
          axios
              .post('users/group/request/update/' + id, postData)
              .then(function (response) {
                if (response.data.code === 200) {
                  self.largeModal = false;
                  self.submitted = false; //Enable Button
                  self.$router.go();
                } else {
                  self.err_msg = response.data.message;
                  self.dismissCountDown = 10;
                }
              })
              .catch(function () {
                self.submitted = false; //Enable Button
              });
        }, 500);
      } else {
        let self = this;
        const id = this.$route.params.id;
        const postData = {
          status: statusId,
          reason: null,
        };
        axios
            .post('users/group/request/update/' + id, postData)
            .then(function (response) {
              if (response.data.code === 200) {
                self.largeModal = false;
                self.submitted = false; //Enable Button
                self.$router.go();
              } else {
                self.err_msg = response.data.message;
                self.dismissCountDown = 10;
              }
            })
            .catch(function () {
              self.submitted = false; //Enable Button
            });
      }
    },

  },
};
</script>
<style>
div .error {
  color: red;
}

.imageDimenstion {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.hasError label {
  color: red;
}

.imageFormat {
  width: 100%;
}
</style>
